import React from 'react';
import { useState } from 'react';
import {useTelegram} from "../../hooks/useTelegram";
import {Image,Container,Row,Col,Button,Offcanvas,Stack} from "react-bootstrap";

const Header = () => {
    const {user} = useTelegram();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <Container>
            <Row>
                <Col className={'align-self-center'}><img src='/logo.svg' className={"img-fluid p-2"} alt="..."/></Col>
                <Col className={'text-end'}>
                    <Button variant="light" onClick={handleShow}>
                        <i className="fa fa-bars" aria-hidden="true"></i>
                    </Button>

                    <Offcanvas show={show} onHide={handleClose} placement={'end'} backdrop={'static'}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Меню</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Row>
                                <Col><Image src={'/temp_image.png'}></Image></Col>
                                <Col>
                                    <Row>@{user?.username}</Row>
                                    <Row>{user?.first_name}</Row>
                                </Col>
                            </Row>

                            <Stack gap={3}>
                                <div className="p-2">First item</div>
                                <div className="p-2">Second item</div>
                                <div className="p-2">Third item</div>
                            </Stack>
                        </Offcanvas.Body>
                    </Offcanvas>
                </Col>
            </Row>
        </Container>
    )
};

export default Header;